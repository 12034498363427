import {Component, useEffect} from 'react';
import React from 'react'

declare global {
    interface Window {
        $crisp: any
        CRISP_WEBSITE_ID: string
    }
}

export function Crisp(props) {

    useEffect(() => {
        window.$crisp=[];
        window.CRISP_WEBSITE_ID='3fb2e776-00f3-444f-b4a5-06a17e838dd7';
        let s = document.createElement('script') as any;
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        document.getElementsByTagName('head')[0].appendChild(s);
    })

    return <></>

}
