import React from 'react';
import {TEAM} from '../data/team';


export function SectionTeam() {
    return (
        <section id='team' className='pt-24 space-y-20'>

            <h1 className='text-2xl sm:text-3xl font-bold text-center'>{TEAM.title}</h1>

            {/* todo : department tabs */}

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                {TEAM.cards.map(pos => (
                    <div className={
                        'flex flex-col items-center ' +
                        'p-8 border border-gray-200 rounded-xl '
                        + 'transition-all duration-300 hover:shadow-2xl hover:shadow-dark-500/10'
                    }>
                        <img
                            className='rounded-2xl aspect-square grow-0 shrink-0 w-44 md:w-auto'
                            src={pos.image}
                            alt={`${pos.name} - Axelered Doo`}
                        />
                        <div className='mt-4 md:mt-8'>
                            <div className='mt-8 flex flex-col sm:flex-row items-center justify-center space-x-4'>
                                <p className='text-xl font-semibold'>{pos.name}</p>
                                <p className='text-primary-700'>{pos.position}</p>
                            </div>
                            <div className='mt-4 pt-4 border-t border-gray-200 text-center'>
                                <p>{pos.details}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/*<div className='text-center'>*/}
            {/*    <ButtonSecondary label='View all Positions' />*/}
            {/*</div>*/}

        </section>
    )
}

