import React, {useEffect, useRef} from 'react';

const FORM_URL = 'https://plugins.crisp.chat/urn:crisp.im:contact-form:0/contact/3fb2e776-00f3-444f-b4a5-06a17e838dd7';

/**
 * Cause crisp form iframe to load async, as Google page score is heavily impacted
 * by this shitty implementation of a form.
 */
export function CrispForm(props) {
    const ref = useRef();

    useEffect(() => {
        // This should be executed only inside the browser
        // https://dev.to/elisabethleonhardt/how-to-use-client-side-only-packages-with-ssr-in-gatsby-and-nextjs-3pfa
        if (!ref.current) return;
        ref.current.src = FORM_URL;
    }, [ref.current])

    return (
        <iframe
            title="Contact Form"
            src=""
            referrerPolicy="origin"
            sandbox="allow-forms allow-popups allow-scripts"
            width="100%"
            height="600px"
            frameBorder="0"
            ref={ref}
        />
    )
}
