import React from 'react';
import { Button } from '../components/Button';
import { ButtonSecondary } from '../components/ButtonSecondary';
import phone1 from '../assets/phone1.png'
import cube4 from '../assets/cube4.png';
import cube8f from '../assets/cube8f.png';


export function SectionHero() {
    return (
        <section id='hero' className='flex flex-col space-y-16 pt-48 space-y-20 pb-24'>

            <div className='flex flex-col items-center text-center'>

                <p className='text-xl'>WE MAKE SOFTWARE</p>
                <h1 className='mt-2 text-2xl sm:text-3xl md:text-4xl font-bold'>
                    Technology belongs to everyone
                </h1>

                <div className='flex flex-col sm:flex-row mt-10 space-y-4 sm:space-y-0 sm:space-x-4'>
                    <Button label='Contact us' href='#contact'/>
                    <ButtonSecondary label='How it works' href='#hiw'/>
                </div>

            </div>

            <div className='flex justify-center relative px-4'>
                <img
                    className='absolute w-[15rem] md:w-[20rem] max-md:bottom-[-4rem] md:top-[-4rem] left-[-6rem]'
                    src={cube8f}
                    role='presentation'
                    alt=''
                />
                <img
                    className='absolute w-[20rem] md:w-[30rem] top-[-8rem] right-[-12rem]'
                    src={cube4}
                    role='presentation'
                    alt=''
                />
                <img
                    className='relative w-full max-w-[520px] max-h-[650px]'
                    src={phone1}
                    role='presentation'
                    alt=''
                />
            </div>

        </section>
    )
}

