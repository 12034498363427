import cn from '../assets/people/cn.jpg'
import pg from '../assets/people/pg.jpg'
import bs from '../assets/people/bs.jpg'
import gf from '../assets/people/gf.jpg'
import r1 from '../assets/people/r1.jpg'
import r2 from '../assets/people/r2.jpg'
import r3 from '../assets/people/r3.jpg'

interface Type {
    title: string
    cards: {
        image: string
        name: string
        position: string
        details: string
    }[]
}

export const TEAM: Type = {
    title: 'Our Global Team',
    cards: [
        {
            image: cn,
            name: 'Claudio Nardin',
            position: 'CEO',
            details: '“To solve a problem you first have to understand it”',
        },
        /*{
            image: bs,
            name: 'Borut Svara',
            position: 'Advisor',
            details: 'Praesent nibh mi, cursus at nisl ac, sodales tincidunt massa.',
        },*/
        {
            image: gf,
            name: 'Giada Franzolini',
            position: 'PM',
            details: '”I have to work, I don\'t have time to invent quotations”',
        },
        {
            image: pg,
            name: 'Pierluigi Giglio',
            position: 'UX Expert',
            details: '”A good UX is when the user does not have to think”',
        },
        {
            image: r1,
            name: 'Luciano Lorenzi',
            position: 'Dev',
            details: '”Don\'t write code for the machine, write it for the one who will have to maintain it after you”',
        },
        {
            image: r2,
            name: 'Marko Kojanec',
            position: 'Dev',
            details: '”People don\'t hate technology, what they really hate are bad programmers”',
        },
        {
            image: r3,
            name: 'Tjaš Devetak',
            position: 'Dev',
            details: '”It\'s easy to take famous quotes and change a few words, but this is what is often done with code as well”',
        },
    ]
}
