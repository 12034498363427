import React from 'react';
import { Button } from '../components/Button';
import axeleredSvg from '../assets/axelered.svg'
import { Container } from '../components/Container';
import { Axelered } from '../assets/axelered';


export function Header() {
    return (
        <header>

            <div className='h-24' />

            <div className='z-50 fixed top-0 left-0 right-0 bg-dark-500'>
                <Container className='h-20 md:h-24 flex items-center space-x-2'>
                    <Axelered className='h-10 fill-white' />
                    <div className='flex-1' />
                    <Button className='hidden sm:block' label='Contact us' href='#contact' />
                </Container>
            </div>

        </header>

    )
}

