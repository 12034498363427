import React from 'react';
import { SERVICES } from '../data/services';
import { IconBadge } from '../components/IconBadge';

export function SectionServices() {
    return (
        <section id='services' className='flex flex-col py-24 space-y-20'>

            <div className='text-center'>
                <h1 className='text-2xl sm:text-3xl font-bold'>{SERVICES.title}</h1>
                {SERVICES.subtitle && <p className='mt-6'>{SERVICES.subtitle}</p>}
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 lg:gap-x-24'>
                {SERVICES.cards.map(({ title, subtitle, details, ...rest}) => (
                    <div className={
                        'flex flex-col items-start space-y-4 py-8 px-2 max-sm:-mx-2 sm:px-8 bg-dark-500 rounded-xl '
                        + 'transition-all duration-300 sm:hover:-m-4 sm:hover:p-12 hover:bg-dark-400 '
                        + 'group hover:rounded-b-[2rem] hover:rounded-t-none '
                        + 'relative'
                    }>
                        <div className={
                            'absolute top-0 left-1/2 right-1/2 h-1 transparent ' +
                            'transition-all duration-300 ' +
                            'group-hover:left-0 group-hover:right-0 group-hover:bg-primary-500'
                        }/>
                        <IconBadge {...rest} />
                        <h1 className='text-xl'>{title}</h1>
                        <p>{subtitle}</p>
                        <p>{details}</p>
                    </div>
                ))}
            </div>

            {/*<div>*/}
            {/*    <ButtonSecondary label='Learn more' />*/}
            {/*</div>*/}

        </section>
    )
}

