import React from 'react';
import classNames from 'classnames';

export type NodeOrComponent<P = {}> = React.ReactNode | React.FunctionComponent<P> | React.ComponentClass<P>;

export function renderFromNodeOrComponent<
    N extends NodeOrComponent<P>,
    P = React.InputHTMLAttributes<N> & React.HTMLAttributes<N> | null
>(
    noc?: N,
    props?: P,
): React.ReactElement | undefined {
    if (!noc) return undefined;
    return React.isValidElement(noc) ? noc : React.createElement(noc as any, props);
}

export function renderIconFromNodeOrComponent<
    N extends NodeOrComponent,
    P = React.InputHTMLAttributes<N> & React.HTMLAttributes<N> | null
>(
    noc?: N,
    props?: P,
): React.ReactElement | undefined {
    if (!noc) {
        return undefined;
    } else if (React.isValidElement(noc)) {
        return noc;
    } else {
        return React.createElement(noc as any, {
            ...props,
            className: classNames('h-6 w-6 shrink-0 grow-0', (props as any)?.className),
        });
    }
}

export type HeadingTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export function renderHeading<H extends HeadingTypes>(
    type: H,
    props?: React.ComponentProps<H>
): React.ReactElement {
    return React.createElement(type, props)
}

export type ContentOrFunction<S extends {} | undefined = undefined>
    = React.ReactElement | React.ReactElement[]
    | ((slot: S) => React.ReactElement | React.ReactElement[])
    ;

export function renderFunctionContent<S extends {} | undefined = undefined>(
    cof: ContentOrFunction<S> | undefined,
    slot: S,
): React.ReactElement | React.ReactElement[] | undefined {
    if (!cof) return undefined;
    return typeof cof === 'function' ? cof(slot) : cof;
}
