import {
    BriefcaseIcon,
    BugAntIcon,
    ChatBubbleLeftRightIcon,
    ClockIcon,
    KeyIcon, UserIcon,
    WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';
import { SectionCardsData } from '../sections/SectionCards';


export const WHAT_WE_DO: SectionCardsData = {
    title: 'What we offer',
    cards: [
        {
            icon: KeyIcon,
            title: 'Turnkey solutions',
            details: 'A great idea? Have our team develop you a turnkey solution, ' +
                'ready to be deployed in production since day one.'
        },
        {
            icon: ClockIcon,
            title: 'Time material',
            details: 'Need some flexible workforce? Rent experienced developers for as low as 1 month up ' +
                'to few years. You can then hire them directly.'
        },
        {
            icon: BriefcaseIcon,
            title: 'Consulting',
            details: 'Growing and looking for help in finding the best solution? ' +
                'We offer a wide variety of professional services to assist you in scaling.'
        },
    ],
    learnMoreHref: '',
}


export const HOW_IT_WORKS: SectionCardsData = {
    title: 'How it Works',
    cards: [
        {
            icon: ChatBubbleLeftRightIcon,
            title: 'Define the problem',
            details: 'Understanding your needs is our priority. We believe focusing on this phase ' +
                'is the key to any project success.'
        },
        {
            icon: WrenchScrewdriverIcon,
            title: 'Develop a Solution',
            details: 'We meticulously define the workflow, select and coordinate personnel, ' +
                'to avoid time waste and ensure development precision.'
        },
        {
            icon: BugAntIcon,
            title: 'Refine',
            details: 'Perfection is a utopia, but we like to get as close to it as possible. ' +
                'There are always some edges to be smoothed.'
        },
    ]
}
