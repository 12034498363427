import React from 'react';
import { NodeOrComponent, renderFromNodeOrComponent, renderIconFromNodeOrComponent } from './render';
import { ArchiveBoxIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

export function IconBadge(
    {
        icon = ArchiveBoxIcon,
        color = 'bg-red-100',
    }: {
        icon?: NodeOrComponent,
        color?: string,
    }
) {
    return (
        <div className={classNames('p-4 rounded-full', color)}>
            {renderIconFromNodeOrComponent(icon)}
        </div>
    )
}

