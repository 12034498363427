import React from 'react'
import '../index.css'
import { SectionServices } from '../sections/SectionServices';
import { SectionCards } from '../sections/SectionCards';
import { SectionMessage } from '../sections/SectionMessage';
import { SectionHero } from '../sections/SectionHero';
import { SectionContacts } from '../sections/SectionContacts';
import { SectionTeam } from '../sections/SectionTeam';
import { Header } from '../sections/Header';
import { Footer } from '../sections/Footer';
import { Container } from '../components/Container';
import { HOW_IT_WORKS, WHAT_WE_DO } from '../data';
import { Crisp } from '../components/Crisp';

export function Head() {
    return (
        <>
            <title>Axelered</title>
            <meta name='description' content='Turnkey software provider'/>
        </>
    )
}

export default function Home() {
    return (
        <div className='overflow-hidden'>

            {/* Dark section */}
            <div className='bg-dark-600 text-white relative overflow-hidden'>

                <div className={
                    'absolute top-[-50vh] left-[-20vw] ' +
                    'bg-[#0067ff] rounded-[50%] opacity-30 w-[140vw] h-[100vh] ' +
                    'blur-[120px]'
                }/>

                <Container className='relative w-full'>

                    <Header />
                    <SectionHero />
                    <SectionServices />
                    <SectionCards id='wwd' data={WHAT_WE_DO} />
                    <SectionMessage />
                    <SectionCards id='hiw' data={HOW_IT_WORKS} arrows />

                </Container>

            </div>

            {/* Light Section */}

            <Container className='text-gray-900'>

                <SectionTeam />
                {/*<SectionJobs/>*/}
                <SectionContacts />
                <Footer />

            </Container>

            {/* Crisp */}
            <Crisp />

        </div>
    )
}
