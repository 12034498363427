import React from 'react';
import classNames from 'classnames';

export function Button(
    {
        label = 'Press',
        className,
        compact = false,
        href,
    } : {
        label?: string
        className?: string
        compact?: boolean
        href?: string
    }
) {
    return (
        <a
            className={classNames(
                'block px-6 rounded-full text-center text-white font-semibold',
                'bg-gradient-to-r from-[#0047FF] to-[#0023FF]',
                compact ? 'py-1' : 'py-3',
                className
            )}
            href={href}
        >
            {label}
        </a>
    )
}

