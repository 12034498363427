import {
    ComputerDesktopIcon,
    CreditCardIcon,
    DevicePhoneMobileIcon,
    GlobeEuropeAfricaIcon,
    CurrencyRupeeIcon,
    CheckBadgeIcon,
} from '@heroicons/react/24/solid';
import {NodeOrComponent} from '../components/render';
import { CpuChipIcon, LanguageIcon, VideoCameraIcon } from '@heroicons/react/24/outline';

interface Type {
    title: string
    subtitle?: string
    cards: {
        icon: NodeOrComponent,
        color: string
        title: string
        subtitle: string
        details: string
    }[]
}

export const SERVICES: Type = {
    title: 'What we do',
    // subtitle: 'Over time we have put together a distributed team of specialists capable to offer a wide variety of ' +
    //     'solutions to you needs',
    cards: [
        {
            icon: VideoCameraIcon,
            color: 'bg-gradient-to-br from-[#0289FF] to-[#0258FF]',
            title: 'Vision',
            subtitle: 'OpenCV, ScikitImage',
            details: ''
        },
        {
            icon: CpuChipIcon,
            color: 'bg-gradient-to-br from-[#8B96FF] to-[#4353FF]',
            title: 'AI/ML',
            subtitle: 'TensorFlow, Pytorch',
            details: ''
        },
        {
            icon: LanguageIcon,
            color: 'bg-gradient-to-br from-[#28DD9C] to-[#00AE70]',
            title: 'NLP',
            subtitle: 'HuggingFace, Spacy',
            details: ''
        },
        {
            icon: CheckBadgeIcon,
            color: 'bg-gradient-to-br from-[#FF1593] to-[#D60073]',
            title: 'SaaS Apps',
            subtitle: 'Node.js, Python, Java',
            details: ''
        },
        {
            icon: ComputerDesktopIcon,
            color: 'bg-gradient-to-br from-[#C166FF] to-[#9800FF]',
            title: 'Desktop Apps',
            subtitle: 'ElectronJS',
            details: ''
        },
        {
            icon: DevicePhoneMobileIcon,
            color: 'bg-gradient-to-br from-[#8F5FFF] to-[#5206FF]',
            title: 'Mobile Apps',
            subtitle: 'React Native, Flutter, Kotlin, Swift',
            details: ''
        },
        // {
        //     icon: GlobeEuropeAfricaIcon,
        //     color: 'bg-gradient-to-br from-[#0289FF] to-[#0258FF]',
        //     title: 'Websites',
        //     subtitle: 'PHP, Gatsby, WebFlow',
        //     details: ''
        // },
        // {
        //     icon: CreditCardIcon,
        //     color: 'bg-gradient-to-br from-[#8B96FF] to-[#4353FF]',
        //     title: 'Ecommerce',
        //     subtitle: 'Shopify, Magento',
        //     details: ''
        // },
        // {
        //     icon: CurrencyRupeeIcon,
        //     color: 'bg-gradient-to-br from-[#28DD9C] to-[#00AE70]',
        //     title: 'Web3',
        //     subtitle: 'Web3.js, Solidity',
        //     details: ''
        // }
    ]
}
