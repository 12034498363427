import React, { ReactNode } from 'react';
import classNames from 'classnames';

export function Container({ children, className }: { children: ReactNode, className?: string }) {
    return (
        <div
            className={classNames(
                'px-4 sm:px-4 max-w-[1200px] xl:px-0 xl:m-auto',
                className
            )}
            children={children}
        />
    )
}

