import React from 'react';
import phone2 from '../assets/phone2.png'
import {CrispForm} from '../components/CrispForm';


export function Input(
    {
        type,
        name,
        placeholder,
        label,
    } : {
        name: string,
        type: string,
        placeholder: string,
        label?: string,
    }) {
    return (
        <section id='contact' className='flex flex-col'>
            <label
                className='mb-2 font-semibold'
                children={label}
            />
            {type === 'textarea' ? (
                <textarea
                    className='p-3 rounded-lg border-2 border-gray-200 h-16'
                    style={{height: '6rem'}}
                    name={name}
                    placeholder={placeholder}
                />
            ) : (
                <input
                    className='p-3 rounded-lg border-2 border-gray-200'
                    name={name}
                    type={type}
                    placeholder={placeholder}
                />
            )}
        </section>
    )
}


export function SectionContacts() {
    return (
        <section id='contact' className='py-24'>

            {/* Form */}
            <div className='flex items-center md:space-x-8 lg:space-x-40'>

                {/*<div className='flex-1'>*/}
                {/*    <h1 className='mb-10 text-2xl sm:text-3xl font-bold'>Get in touch!</h1>*/}

                {/*    <form className='flex flex-col space-y-6'>*/}
                {/*        <Input name='name' type='text' label='How should we call you?' placeholder='Your name'/>*/}
                {/*        <Input name='contact' type='text' label='Where should we contact you?' placeholder='Your contact'/>*/}
                {/*        <Input*/}
                {/*            name='message' type='textarea'*/}
                {/*            label='What do you want to talk about?' placeholder='A message'*/}
                {/*        />*/}
                {/*        <div className='flex space-x-4'>*/}
                {/*            <input type='checkbox'/>*/}
                {/*            <p>*/}
                {/*                I agree to share this information with Axelered Doo*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </form>*/}

                {/*    <Button className='mt-12 w-full' label='Contact us now' />*/}
                {/*</div>*/}

                <div className='flex-1'>
                    <h1 className='mb-6 text-2xl sm:text-3xl font-bold'>Get in touch!</h1>

                    <p className='mb-6 text-xl'>
                        {'fill the form or drop us a line at '}
                        <a className='font-bold' href='mailto:hi@axelered.com'>hi@axelered.com</a>
                    </p>

                    <CrispForm />
                </div>

                <div className='hidden md:block md:w-[340px] lg:w-auto'>
                    <img
                        src={phone2}
                        role='presentation'
                        alt=''
                    />
                </div>

            </div>

        </section>

    )
}

