import React from 'react';
import cube4 from '../assets/cube4.png'

export function SectionMessage() {
    return (
        <section id='message' className='flex flex-row'>
            <p className='flex-1 min-w-0 text-2xl md:text-3xl my-20 lg:my-48'>
                Our goal is to enable companies to leverage the most cutting-edge technology on the market
            </p>

            <div className='ml-[-2rem] sm:ml-0 lg:ml-4 mr-[-26rem] sm:mr-[-20rem] md:mr-[-15rem] lg:mr-[-5rem]'>
                <img
                    className='w-[32rem]'
                    src={cube4}
                    role='presentation'
                    alt=''
                />
            </div>
        </section>
    )
}

