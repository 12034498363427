import React from 'react';
import { NodeOrComponent, renderFromNodeOrComponent } from '../components/render';
import { ButtonSecondary } from '../components/ButtonSecondary';
import { HowItWorksArrow } from '../assets/howItWorksArrow';
import classNames from 'classnames';

export interface SectionCardsData {
    title: string
    cards: {
        icon: NodeOrComponent,
        title: string
        details: string
    }[]
    learnMoreHref?: string
}

export interface SectionCardsProps {
    id: string
    arrows?: boolean
    data: SectionCardsData
}

export function SectionCards({ id, data, arrows }: SectionCardsProps) {
    return (
        <div id={id} className='flex flex-col py-24 space-y-20'>

            <div className='text-center'>
                <h1 className='text-2xl sm:text-3xl font-bold'>{data.title}</h1>
            </div>

            <div className='grid grid-cols-1 gap-y-8 gap-x-4 md:grid-cols-3 lg:gap-x-24'>
                {data.cards.map((elem, ix) => (
                    <div className={
                        'flex flex-col items-start space-y-8 py-8 px-2 max-md:-mx-2 md:px-8 bg-dark-500 rounded-xl '
                        + 'transition-all duration-300 md:hover:-m-2 md:hover:p-10 hover:bg-dark-400 ' +
                        'relative'
                    }>
                        <div className='p-8 rounded-2xl bg-red-100 bg-gradient-to-br from-[#8F5FFF] to-[#5206FF]'>
                            {renderFromNodeOrComponent(elem.icon as any, { className: 'w-12 h-12' })}
                        </div>

                        {/*<IconBadge {...rest} />*/}
                        <h1 className='text-xl font-semibold'>{elem.title}</h1>
                        <p className='text-primary-one'>{elem.details}</p>
                        {arrows && (ix < (data.cards.length - 1)) && (
                            <HowItWorksArrow className={classNames(
                                'z-10 invisible lg:visible absolute right-0 translate-x-[60%]',
                                ix % 2 !== 0 ? 'translate-y-32 scale-y-[-1]' : ''
                            )}/>
                        )}
                    </div>
                ))}
            </div>

            {data.learnMoreHref && (
                <div>
                    <ButtonSecondary label='Learn more' href={data.learnMoreHref} />
                </div>
            )}

        </div>
    )
}

