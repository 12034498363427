import React from 'react';
import { Axelered } from '../assets/axelered';


export function Footer() {
    return (
        <footer>

            {/* Links */}
            <div className={
                'flex flex-col items-center space-y-6 sm:flex-row sm:space-y-0 '
                + 'py-16 border-t-2 border-gray-200'
            }>
                <Axelered className='h-10 fill-gray-900' />
                <div className='flex-1' />
                <div>Copyright © Axelered Doo</div>
            </div>

        </footer>
    )
}

