import React from 'react';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';

export function ButtonSecondary(
    {
        label = 'Press',
        href,
    } : {
        label?: string,
        href?: string,
    }
) {
    return (
        <a
            className='block space-x-4 px-6 py-3 transition opacity-70 hover:opacity-100'
            href={href}
        >
            <span>{label}</span>
            <ArrowRightCircleIcon className='w-6 h-6 inline'/>
        </a>
    )
}

